import { getPlayers } from "api/lucky77";
import { getWinRate } from "api/lucky77";
import Lucky77Settings from "components/models/Lucky77Settings";
import React, { useEffect } from "react";
import { useState } from "react";
import io from "socket.io-client";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { changeWinRate } from "store/slice/lucky77";
import InfiniteScroll from "react-infinite-scroll-component";
import Sort from "components/Sort/Sort";
import { numberFormatter } from "utils/number";
import Lucky77History from "components/models/Lucky77History";
import getJwtFromLocalStorage from "utils/localStorageUtils";

function Lucky77() {
  const dispatch = useDispatch();
  const winRate = useSelector((state) => state.lucky77.winRate);

  const [isLoading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [users, setUsers] = useState([]);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [orders, setOrders] = useState([]);

  const loadWinRate = async () => {
    try {
      const { data } = await getWinRate();

      dispatch(changeWinRate(data.winRate));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  const loadPlayers = async () => {
    try {
      const { data } = await getPlayers(users.length, 20, orders);

      setHasMore(data?.users?.length === 20);
      setUsers([...users, ...data?.users]);
    } catch (error) {
      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  useEffect(() => {
    loadWinRate();
    loadPlayers();
  }, []);

  const handleOrder = (order) => {
    if (orders.find((o) => o.key === order.key && o.value === order.value)) {
      setOrders((prev) => prev.filter((p) => p.key !== order.key));
    } else if (
      orders.find((o) => o.key === order.key && o.value !== order.value)
    ) {
      setOrders((prev) =>
        prev.map((p) => {
          p.value = order.value;

          return p;
        })
      );
    } else {
      setOrders((prev) => [...prev, order]);
    }
  };

  const loadFilteredData = async (orders) => {
    try {
      const { data } = await getPlayers(0, 20, orders);

      setHasMore(data?.users?.length === 20);
      setUsers(data?.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadFilteredData(orders);
  }, [orders]);

  const [time, setTime] = useState(0);

  const [board_a_coin, setBoardACoin] = useState(0);
  const [board_b_coin, setBoardBCoin] = useState(0);
  const [board_c_coin, setBoardCCoin] = useState(0);

  const [boardAMsgs, setBoardAMsgs] = useState([]);
  const [boardBMsgs, setBoardBMsgs] = useState([]);
  const [boardCMsgs, setBoardCMsgs] = useState([]);

  const [adminJwt, setAdminJwt] = useState(null);

  useEffect(() => {
    const result = getJwtFromLocalStorage("HL_GAME_AUTH_USER_DATA");
    setAdminJwt(result);
  }, []);

  useEffect(() => {
    if (adminJwt) {
      const socket = io(`${process.env.REACT_APP_SOCKET_BASE_URL}/luck-77`, {
        auth: {
          UID: adminJwt,
        },
      });
      socket.on("BOARD_A_COIN_CHANGED_ADMIN", (coin) =>
        setBoardACoin((prev) => (coin ? prev + coin : 0))
      );
      socket.on("BOARD_B_COIN_CHANGED_ADMIN", (coin) =>
        setBoardBCoin((prev) => (coin ? prev + coin : 0))
      );
      socket.on("BOARD_C_COIN_CHANGED_ADMIN", (coin) =>
        setBoardCCoin((prev) => (coin ? prev + coin : 0))
      );
      socket.on("TIK", (time) => {
        setTime(time);

        if (time == 19) {
          setBoardAMsgs([]);
          setBoardBMsgs([]);
          setBoardCMsgs([]);
        }
      });

      socket.on("LUCKY_77_BET_PLACED", (board, msg) => {
        if (board == "A") {
          setBoardAMsgs((prev) => [msg, ...prev]);
        } else if (board == "B") {
          setBoardBMsgs((prev) => [msg, ...prev]);
        } else if (board == "C") {
          setBoardCMsgs((prev) => [msg, ...prev]);
        }
      });

      return () => {
        socket.off("BOARD_A_COIN_CHANGED_ADMIN");
        socket.off("BOARD_B_COIN_CHANGED_ADMIN");
        socket.off("BOARD_C_COIN_CHANGED_ADMIN");
        socket.off("LUCKY_77_BET_PLACED");
        socket.off("TIK");
      };
    }
  }, [adminJwt]);

  const triggerWinBoard = (board) => {
    if (!Boolean(time)) return;

    socket?.emit("LUCKY77_TRIGGER_WIN", board);
    NotificationManager.success("Command successfully sent.");
  };

  return (
    <>
      {showSettingsModal && (
        <Lucky77Settings show onHide={() => setShowSettingsModal(false)} />
      )}

      {showHistoryModal && (
        <Lucky77History show onHide={() => setShowHistoryModal(false)} />
      )}

      {isLoading ? (
        <Container>
          <Row>
            <Col xs={12} className="py-4 text-center">
              <Spinner animation="grow" />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex align-items-baseline mb-3">
              <h3 className="display-5">Win Rate</h3>
              <small
                className="change-link ms-2 text-primary text-decoration-underline cursor-pointer"
                onClick={() => setShowSettingsModal(true)}
              >
                Change
              </small>
              <small
                className="change-link ms-auto text-primary text-decoration-underline cursor-pointer"
                onClick={() => setShowHistoryModal(true)}
              >
                History
              </small>
            </Col>

            <Col xs={4}>
              <h4>Pot A</h4>

              <h1 className="display-5">{winRate.a}%</h1>

              <div
                style={{
                  backgroundColor: "#f1f1f1",
                  maxHeight: 200,
                  height: 200,
                  overflowY: "scroll",
                }}
              >
                {boardAMsgs.map((m, i) => (
                  <p
                    key={i}
                    style={{
                      backgroundColor: i % 2 == 0 ? "#f1f1f1" : "#fff",
                      padding: 8,
                    }}
                  >
                    {m}
                  </p>
                ))}
              </div>
            </Col>
            <Col xs={4}>
              <h4>Pot B</h4>

              <h1 className="display-5">{winRate.b}%</h1>

              <div
                style={{
                  backgroundColor: "#f1f1f1",
                  maxHeight: 200,
                  height: 200,
                  overflowY: "scroll",
                }}
              >
                {boardBMsgs.map((m, i) => (
                  <p
                    key={i}
                    style={{
                      backgroundColor: i % 2 == 0 ? "#f1f1f1" : "#fff",
                      padding: 8,
                    }}
                  >
                    {m}
                  </p>
                ))}
              </div>
            </Col>
            <Col xs={4}>
              <h4>Pot C</h4>

              <h1 className="display-5">{winRate.c}%</h1>

              <div
                style={{
                  backgroundColor: "#f1f1f1",
                  maxHeight: 200,
                  height: 200,
                  overflowY: "scroll",
                }}
              >
                {boardCMsgs.map((m, i) => (
                  <p
                    key={i}
                    style={{
                      backgroundColor: i % 2 == 0 ? "#f1f1f1" : "#fff",
                      padding: 8,
                    }}
                  >
                    {m}
                  </p>
                ))}
              </div>
            </Col>

            <Col xs={12} className="mt-4">
              <p className="live">
                Live beans. Game end in: {Boolean(time) ? time : "--"} second.
              </p>
            </Col>

            <Col xs={4}>
              <Button
                size="sm"
                style={{ minWidth: 100 }}
                onClick={() => triggerWinBoard("A")}
              >
                {numberFormatter(board_a_coin)}
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                size="sm"
                style={{ minWidth: 100 }}
                onClick={() => triggerWinBoard("B")}
              >
                {numberFormatter(board_b_coin)}
              </Button>
            </Col>

            <Col xs={4}>
              <Button
                size="sm"
                style={{ minWidth: 100 }}
                onClick={() => triggerWinBoard("C")}
              >
                {numberFormatter(board_c_coin)}
              </Button>
            </Col>

            <Col xs={12} className="mt-4">
              <small className="text-muted">
                Note: Click on a button to mark it as a winning board. This
                action will be applied for only one round. also, you can change
                your decision anytime by clicking another button..
              </small>
            </Col>

            <Col xs={12} className="py-4">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Lucky 77 Players</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <InfiniteScroll
                    dataLength={users.length} //This is important field to render the next data
                    next={loadPlayers}
                    hasMore={hasMore}
                    loader={
                      <div className="text-center py-4">
                        <Spinner animation="grow" />
                      </div>
                    }
                    scrollableTarget="main-panel"
                  >
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="border-0">User ID</th>
                          <th className="border-0">Name</th>
                          <th className="border-0">
                            <div className="text-center">
                              Total Played <br /> Games{" "}
                              <Sort
                                orders={orders}
                                name="totalPlayed"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Total Games <br /> Win
                              <Sort
                                orders={orders}
                                name="totalWin"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Win Rate <br />
                              <Sort
                                orders={orders}
                                name="winRate"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Total used <br /> beans
                              <Sort
                                orders={orders}
                                name="totalSpend"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Total earned <br /> beans
                              <Sort
                                orders={orders}
                                name="totalEarned"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Available Beans <br />
                              <Sort
                                orders={orders}
                                name="beans"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((u, i) => (
                          <tr key={i}>
                            <td>{u?.userId}</td>
                            <td className=" max-w-100-p">
                              {u?.nickname || "N/A"}

                              {Boolean(u?.playing) && (
                                <span
                                  style={{
                                    width: 10,
                                    height: 10,
                                    background: "green",
                                    borderRadius: 5,
                                    display: "inline-block",
                                    marginLeft: 8,
                                  }}
                                  title="Playing"
                                ></span>
                              )}
                            </td>
                            <td className="text-center">{u.totalPlayed}</td>
                            <td className="text-center">{u.totalWin}</td>
                            <td className="text-center">
                              {parseInt(u.winRate * 100)}%
                            </td>
                            <td className="text-center">
                              {numberFormatter(u.totalSpend)}
                            </td>
                            <td className="text-center">
                              {numberFormatter(u.totalEarned)}
                            </td>
                            <td className="text-center">
                              {numberFormatter(u.beans || 0)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Lucky77;
